<template src="@/../../../templates/brand/components/templates/shop/product/ProductDetailWrapper.html"> </template>

<script>
// import _ from 'lodash/fp'
import { mapActions, mapState } from 'vuex'
import _ from 'lodash/fp'
import productDetail from '@/components/shop/product/ProductDetail.vue'
import productCustomize from '@/components/shop/product/ProductCustomize.vue'
import countDown from '@/components/Countdown.vue'
import mainNav from '@/components/MainNav.vue'
import Loader from '@/components/Loader.vue'

export default {
  name: 'ProductWrapper',
  components: { mainNav, productDetail, productCustomize, countDown, Loader },
  watch: {
    $route: function (val) {
      this.loaded = false
      this.getProduct({ id: val.params.fkey, getBy: 'familyKey', detail: true })
      // this.getProduct({ id: `${val.params.fkey}/${val.params.vkey}`, getBy: 'familyKey', detail: true })
      .then(() => {
        this.update = String(new Date().getTime())
        this.loaded = true
      })
    }
  },
  data () {
    return {
      update: '',
      isProgram: false,
      isCollection: false,
      section: '',
      title: '',
      loaded: false,
      loadingMsg: 'loading...'
    }
  },
  methods: {
    ...mapActions([
      'getCollections',
      'setCollection',
      'getCollectionProducts',
      'getProgramProducts',
      'programSummaryUpdate',
      'getProduct',
      'getProductFamily',
      // 'getCartProducts',
      'getProgram'
    ])
  },
  computed: {
    ...mapState({
      products: ({ Product }) => Product.atomicFamilies, // familyVariations,
      productList: ({ Product }) => Product.listFamilies,
      program: ({ Collection }) => Collection.program,
      collections: ({ Collection }) => Collection.collections,
      collection: ({ Collection }) =>
        Collection.collection || { content: { en: {}, fr: {} } },
      user: ({ App }) => App.user,
      product: ({ Product }) => Product.atomicProduct, // || {content: {en: {}, fr: {} }}, //product,
      filteredSet: ({ Product }) => Product.filteredSet,
      navigationContext: ({ navigationContext }) => navigationContext
    }),
    returnURL () {
      return (
        this.navigationContext ||
        this.$route.path.slice(0, this.$route.path.indexOf('/product'))
      )
    },
    productSet () {
      return this.filteredSet && this.filteredSet.length
        ? this.filteredSet
        : this.productList
    },
    next () {
      let _index = this.productSet.findIndex(i => i._id === this.product._id)
      if (!~_index || _index === this.productSet.length - 1) return false
      let _prod = this.productSet[_index + 1]
      let _prodpath =
        (_prod.variationField && _prod.variationField.key) || _prod.variationKey
      return (
        this.$route.path.slice(0, this.$route.path.indexOf('product') + 8) +
        _prod.familyKey +
        (_prodpath ? '/' + _prodpath : '')
      )
    },
    prev () {
      let _index = this.productSet.findIndex(i => i._id === this.product._id)
      if (!~_index || _index === 0) return false
      let _prod = this.productSet[_index - 1]
      let _prodpath =
        (_prod.variationField && _prod.variationField.key) || _prod.variationKey
      return (
        this.$route.path.slice(0, this.$route.path.indexOf('product') + 8) +
        _prod.familyKey +
        (_prodpath ? '/' + _prodpath : '')
      )
    }
  },
  beforeDestroy () {
    this.loaded = false
    // this.$emit('teardown')
  },
  async created () {
    const _keys = this.$route.params.fkey + (this.$route.params.vkey ? `/${this.$route.params.vkey}` : '') //`${thival.params.pidute.params.vkey}`
    // const _keys = this.$route.params.pid //`${this.$route.params.fkey}/${this.$route.params.vkey}`
    let _ent
    if (this.$route.params.collection) {
      this.isProgram = false
      this.isCollection = true
      this.section = this.$route.params.collection
      if (!this.collections || this.collection.url !== this.section) {
        await this.getCollections()//.then(() => {
        this.setCollection(this.$route.params.collection)
        //   return true
        // })
      }
      _ent = this.collection
      if (!this.productList.length) {
          await this.getCollectionProducts({
            id: this.collection._id, //this.$route.params.collection,
            key: 'url'
          })
      }
      // this.section = this.$route.params.collection;
    } else if (this.$route.params.program) {
      this.isProgram = true
      this.isCollection = false
      this.section = this.$route.params.program
      if (!this.program || this.program.url !== this.section) {
        const _data = await this.getProgram({
          id: this.$route.params.program,
          key: 'url'
        })
        await this.getProgramProducts({
          id: this.$route.params.program,
          key: 'url',
          list: _data.settings && _data.settings.layout === 'standard'
        })
      }
      _ent = this.program
    }

    if (_.find(p => p.key === _keys.split('/').join(''), this.isCollection ? this.products : this.productList)) {
      // tell price grid to update
      this.getProductFamily(_keys);
      this.update = (String)(new Date().getTime());
      this.$broadcast('product.changed');
      this.title = _ent.content[this.userLang].name;
      this.loaded = true;
      // transition.next();
    } else {
      this.title = _ent.content[this.$i18n.locale].name
      await this.getProduct({ id: _keys, getBy: 'familyKey', detail: true })
      this.update = String(new Date().getTime())
      this.$nextTick(() => {
        this.loaded = true
      })
    }
  },
}
</script>

<style src="@/../../../templates/brand/components/styles/shop/product/ProductDetailWrapper.css"></style>
